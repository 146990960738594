<template>
  <div>

    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <analytics-congratulation />
      </b-col></b-row>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <number-carton-way-tunisia :products="products" />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <number-carton-received-tunisia :products="products" />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <number-carton-way-brazil :products="products" />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <number-carton-received-brazil :products="products" />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {

  BCol, BRow,

} from 'bootstrap-vue'

import { onMounted } from '@vue/composition-api'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import NumberCartonWayTunisia from './NumberCartonWayTunisia.vue'
import NumberCartonWayBrazil from './NumberCartonWayBrazil.vue'
import NumberCartonReceivedBrazil from './NumberCartonReceivedBrazil.vue'
import NumberCartonReceivedTunisia from './NumberCartonReceivedTunisia.vue'
import useProduct from './useProduct'

export default {
  components: {

    BCol,
    BRow,
    AnalyticsCongratulation,
    NumberCartonWayTunisia,
    NumberCartonReceivedTunisia,
    NumberCartonReceivedBrazil,
    NumberCartonWayBrazil,
  },

  data() {
    return {

    }
  },
  computed: {

  },
  created() {
  },
  methods: {

  },
  setup() {
    const { products, getProducts, columns } = useProduct()

    onMounted(() => {
      getProducts()
    })

    return {
      products,
      getProducts,
      columns,
    }
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
