import { ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'

export default function useProduct() {
  const products = ref([])

  const columns = ref([
    {
      label: 'Item code',
      field: 'item_code',
      filterOptions: {
        enabled: true,
        placeholder: 'Item code',
      },
    },

    {
      label: 'Description',
      field: 'description',
      filterOptions: {
        enabled: true,
        placeholder: 'Description',
      },
    },
    {
      label: 'EAN',
      field: 'ean',
      filterOptions: {
        enabled: true,
        placeholder: 'EAN',
      },
    },
    {
      label: 'Action',
      field: 'action',
    },
  ])

  const getProducts = async () => {
    const { data } = await axiosIns.get('/products/', { params: { is_deleted: false } })
    products.value = data
  }

  return {
    products,
    getProducts,
    columns,
  }
}
